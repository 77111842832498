/* router-transitions */
body,
html {
  height: 100%;
}
#root {
  height: 100%;
}
/* .pirupi-enter {
  opacity: 0;
  z-index: 1;
}

.pirupi-enter.pirupi-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}
.pirupi-exit {
  display: none;
  z-index: 0;
}

.pirupi-exit.pirupi-exit-active {
  display: none;
} */
